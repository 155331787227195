<template>
  <div>
    <!-- 上半部 -->
    <div class="row mb-1">
      <!-- 按鈕 -->
      <div class="col-md-5 col-12 mb-1">
        <!-- 執行 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="ParticipantAction"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            執行
          </button>
          <ul class="dropdown-menu" aria-labelledby="ParticipantAction">
            <li>
              <button class="dropdown-item" @click="showModal('exportExcel')">
                匯出
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('del')">
                刪除
              </button>
            </li>
          </ul>
        </div>
        <!-- 進階搜尋 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 mb-1 fw-bolder">
        <p>
          搜尋條件:
          <span class="badge bg-primary me-1 mb-1">
            發佈狀態:{{ recordAdvancedSearchData.singleSelect.pushStatus }}
          </span>
        </p>
        <p v-if="recordAdvancedSearchData.timeRange.switch">
          搜尋區間:
          <span
            v-if="
              recordAdvancedSearchData.timeRange.startTime &&
              recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
              recordAdvancedSearchData.timeRange.endTime
            }}</span
          >
          <span
            v-if="
              recordAdvancedSearchData.timeRange.startTime &&
              !recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
          >
          <span
            v-if="
              !recordAdvancedSearchData.timeRange.startTime &&
              recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
          >
        </p>
      </div>
    </div>
    <!-- dataTable -->
    <div class="tw-container">
      <div class="management-datatable">
        <DataTable
          class="p-datatable-sm"
          :scrollHeight="`${scrollHeight}px`"
          :value="linePushList"
          dataKey="id"
          :loading="dataTabelLoading"
          :rowHover="true"
          :paginator="true"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rows="20"
          :rowsPerPageOptions="[20, 50, 100]"
          :scrollable="true"
          currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
          v-model:filters="searchGlobal"
          filterDisplay="menue"
          stateStorage="local"
          stateKey="dt-state-waitingpush-local"
          sortField="sequence"
          :sortOrder="1"
          v-model:selection="selectItems"
        >
          <template #header>
            <div class="d-flex align-items-center">
              <input
                class="form-control me-2"
                v-model="searchGlobal['global'].value"
                placeholder="表格搜尋: 發佈順序，編號，商品，LINE群，按鈕文字，建立時間"
                style="max-width: 99%"
              />
            </div>
          </template>
          <!-- 多選 -->
          <Column
            selectionMode="multiple"
            style="min-width: 50px; max-width: 50px"
          ></Column>
          <!-- 發佈順序 -->
          <Column
            field="sequence"
            header="發佈順序"
            sortable
            style="min-width: 80px; max-width: 80px"
          >
          </Column>
          <!-- 編號 -->
          <Column
            field="id"
            header="編號"
            sortable
            style="min-width: 80px; max-width: 80px"
          >
          </Column>
          <!-- 圖示 -->
          <Column field="" header="圖示" style="min-width: 80px">
            <template #body="{ data }">
              <Image
                class="table-img"
                :imageHash="data.imageHash"
                size="s"
                v-if="data.imageHash"
                :alt="data.imageHash"
              ></Image>
              <img
                class="table-img"
                v-else
                src="@/assets/other-images/noImg.png"
                alt=""
              />
            </template>
          </Column>
          <!-- 商品 -->
          <Column
            field="merchandiseName"
            header="商品"
            sortable
            style="min-width: 100px"
          >
          </Column>
          <!-- LINE群 -->
          <Column
            field="lineGroupName"
            header="LINE群"
            sortable
            style="min-width: 130px"
          >
          </Column>
          <!-- 按鈕文字 -->
          <Column
            field="buttonText"
            header="按鈕文字"
            sortable
            style="min-width: 130px"
          >
          </Column>
          <!-- 發布狀態 -->
          <Column
            field="published"
            header="發佈狀態"
            sortable
            style="min-width: 80px"
          >
            <template #body="{ data }">
              <p class="text-success" v-if="data.published">已發佈</p>
              <p class="text-danger" v-else>未發佈</p>
            </template>
          </Column>
          <!-- 時間 -->
          <Column
            field="createTime"
            header="建立時間"
            sortable
            style="min-width: 110px; max-width: 180px"
          >
            <template #body="{ data }"> {{ data.createTime }} </template>
          </Column>
          <template #empty>
            <p class="text-center fw-bolder text-primary">
              目前尚未有任何資料 0..0
            </p>
          </template>
        </DataTable>
      </div>
    </div>
  </div>

  <!-- 匯出 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="exportExcelModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">匯出 Excel</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>是否將所選擇到的推播匯出成 Excel 格式 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="exportExcel"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="modal-title tw-border-start border-danger text-danger fw-bolder"
          >
            刪除
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          是否將所選擇到的推播刪除?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="del">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';

export default {
  components: {DataTable, Column, ColumnGroup, Row},
  data() {
    return {
      // modal
      exportExcelModal: {},
      delModal: {},
      advancedSearchModal: {},
      // data
      serverToken: '',
      storeId: 0,
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      linePushList: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 選擇到的物件
      selectItems: [],
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'id',
        data: []
      },
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        singleSelect: {
          pushStatus: '未發佈',
        }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  created() {
    this.initialization()
    this.advancedSearch()
  },
  mounted() {
    this.createModals(['advancedSearchModal','delModal','exportExcelModal'])
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-waitingpush-local')
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-waitingpush-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [{
        id: 'pushStatus',
        key: '發佈狀態',
        value: [
          {
            id: 'A1',
            value: '未發佈'
          }, 
          {
            id: 'A2',
            value: '已發佈'
          },
          {
            id: 'A3',
            value: '全部'
          }
        ]
      }]
      // 進階搜尋
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(90, 'days').format('YYYY-MM-DD')
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === 'exportExcel') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆推播')
        this.propsSelectInfos.data = this.selectItems
        this.exportExcelModal.show()
      } else if (status === 'del') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆推播')
        this.propsSelectInfos.data = this.selectItems
        this.delModal.show()
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      }
    },
    // 進階搜尋
    advancedSearch() {
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      this.selectItems = []
      const query = {"columns":[{"columnName":"id","siteObjectType":43}],"distinct":true,"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":42},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"deleted","siteObjectType":42},"operator":{"operator":"=","type":1,"value":false}}],"operator":1},"joins":[{"leftColumn":{"columnName":"storeLineGroupLinkId","siteObjectType":43},"rightColumn":{"columnName":"id","siteObjectType":42},"type":0}],"siteObjectType":43,"sqlCommandType":1}
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 43
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 43
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 發佈狀態
      if (this.advancedSearchData.singleSelect.pushStatus !== '全部') {
        query.group.groups.push({
          column: {
            columnName: "published",
            siteObjectType: 43,
          },
          operator: {
            operator: '=',
            type: 1,
            value: this.advancedSearchData.singleSelect.pushStatus === '已發佈' ? true : false,
          },
        })
      }
      this.getLinePushList(query)
    },
    // 取得推播資料
    getLinePushList(query) {
      const vm = this
      const getLinePushListApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getMerchandise{getMerchandisePictureLinks},getStoreLineGroupLink{getSummary}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getLinePushListApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const linePushList = res.data[0].objects
            vm.linePushListMap(linePushList)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 推播資料整理
    linePushListMap(linePushList) {
      this.linePushList = []
      console.log(linePushList)
      linePushList.forEach(item => {
        // 原資料
        const object = {
          id: item.id,
          buttonText: item.displayText,
          createTime: this.$methods.moment(item.createTime).format("YYYY-MM-DD HH:mm:ss"),
          imageHash: '',
          lineGroupName: item.storeLineGroupLink.summary.groupName,
          merchandiseName: item.merchandise.name,
          published: item.published,
          sequence: item.sequence + 1,
        }
        // 需特別處理
        // 推播商品圖片
        item.merchandise.merchandisePictureLinks.some(img => {
          if (img.front) object.imageHash = img.imageHash
        })
        this.linePushList.push(JSON.parse(JSON.stringify(object)))
      })
      console.log(this.linePushList)
      this.$methods.switchLoading('hide')
      this.dataTabelLoading = false
      this.advancedSearchModal.hide()
    },
    // 匯出 Excel 
    exportExcel() {
      const token = this.serverToken;
      let exportExcelArr = []
      this.selectItems.forEach((item) => {
        exportExcelArr.push(item.id)
      })
      const ids = exportExcelArr.toString()
      const exportExportUrl = `${process.env.VUE_APP_API}/download/exportDataTableExcel?authorization=${token}&siteObjectTypeIndex=43&ids=${ids}`;
      window.open(exportExportUrl, "_blank");
      this.selectItems = []
      this.exportExcelModal.hide()
    },
    // 刪除line推播
    del() {
      this.$methods.switchLoading('show')
      const vm = this
      const delApi = `${process.env.VUE_APP_API}/apps/line/deleteMerchLinePushQueue`
      const header = {
        authorization: this.serverToken
      }
      const merchLinePushQueueIds = []
      this.selectItems.forEach(item => {
        merchLinePushQueueIds.push(item.id)
      })
      console.log(`[${merchLinePushQueueIds}]`)
      const data = new FormData();
      data.append("merchLinePushQueueIds", merchLinePushQueueIds);
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.advancedSearch()
            vm.delModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    }
  }
}
</script>